<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <v-card class="signup-card">
                <v-toolbar dense flat :color="primaryColor" dark>
                    <v-toolbar-title>
                        <span v-if="!isReady">Checking profile status...</span>
                        <span v-if="isReady">Profile status</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text v-if="!isReady">
                    <p class="mb-0">
                        <v-progress-circular :color="primaryColor" indeterminate width="2" size="16" class="mr-2"></v-progress-circular>
                        <!-- Redirecting to <a :href="signupURL">the sign up form</a>... -->
                    </p>
                </v-card-text>
                <v-card-text v-if="isReady">
                    <p v-if="user.xentri_id">
                        <span>Protected with Xentri.</span><br>
                        <!-- TODO: maybe do a different route that's for user prefs instead of setup? -->
                        <router-link :to="{ name: 'user-connect-xentri' }">Manage authentication settings</router-link>
                    </p>
                    <p v-if="!user.xentri_id">
                        <span>Action required: <router-link :to="{ name: 'user-connect-xentri' }">Create Xentri profile</router-link></span>
                    </p>
                    <p v-if="user.account_id">
                        <!-- <span>Created account</span><br> -->
                        <router-link :to="{ name: 'user-account-dashboard' }">Manage account</router-link>
                    </p>
                    <p v-if="!user.account_id">
                        <span>Action required: <router-link :to="{ name: 'user-connect-account' }">Create account</router-link></span>
                    </p>
                </v-card-text>
            </v-card>
            <p class="mt-6 grey--text text--darken-2 text-center"><router-link :to="{ name: 'user-dashboard' }">Continue to dashboard</router-link></p>
        </v-col>
    </v-row>
</template>

<style>
.signup-card p.prompt {
    font-size: 1.3em;
    color: #333333;
}
.signup-card p.help {
    font-size: 1.1em;
    color: #555555;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { isValidProductLookupKey } from '@/sdk/input';

export default {
    components: {
    },
    data: () => ({
        isReady: false,
        isRedirecting: false,
        product: null,
        submitTimestamp: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
    }),
    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
            account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
    },
    methods: {
        redirect() {
            if (this.isRedirecting) {
                // redirect already in progress
                return;
            }
            this.isRedirecting = true;
            // show a link after 2 seconds in case auto-redirect fails or takes a long time
            setTimeout(() => {
                this.showRedirectCard = true;
                this.isRedirecting = false;
            }, 2000);
            // use replace so that when user taps 'back' button from there, they won't
            // end up being redirected again to where they just wanted to come back from
            /* xyzzy restore if needed
            if (typeof window.location.replace === 'function') {
                window.location.replace(this.signupURL);
            } else {
                window.location.href = this.signupURL;
            }
                */
        },
        async check() {
            try {
                const response = await this.$client.user(this.user.id).user.check({ xentri: true, unicornsprings: true });
                console.log(`Got response from check: ${JSON.stringify(response)}`);
                await this.$store.dispatch('loadUser');
            } catch (err) {
                // TODO: show an error message
                console.log(`Got error from check: ${err.message}`);
            } finally {
                this.isReady = true;
            }
        },
    },
    mounted() {
        if (this.$route.query.product && isValidProductLookupKey(this.$route.query.product)) {
            this.product = this.$route.query.product;
        }
        this.check();
    },
};
</script>
